
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import HamburgerButton from '~/components/navigation/HamburgerButton.vue';
import { NavItem } from '~/models/Navigation';
import { Profile } from '~/models/Profile';

@Component({
  components: { HamburgerButton },
})
export default class NavigationMobile extends Vue {
  @Prop({ type: Array, required: true }) navItems!: Array<NavItem>;
  @Prop({ type: Object, required: true }) userData!: Partial<Profile>;
  @Prop({ type: Function, required: true }) logoutCallback!: () => void;

  private get settingsItem(): NavItem | undefined {
    return this.navItems.find((item) => item.icon === 'settings');
  }

  private isOpen = false;

  private handleHamburgerButtonClick() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }

  private handleMenuItemClick() {
    this.isOpen = false;
    document.body.style.overflow = 'unset';
  }
}
