import { Context } from '@nuxt/types';
import { BrandHelper } from '~/utils/BrandHelper';
import { Brand } from '~/models/Brand';

export default function ({ route, redirect }: Context) {
  const browserLanguage: string = navigator.language || 'en';
  const preferredLanguage: string = browserLanguage.split('-')[0];

  if (BrandHelper.getBrand() === Brand.Frontnode) {
    if (preferredLanguage === 'et' && !route.path.startsWith('/et')) {
      return redirect('/et' + (route.path !== '/' ? route.fullPath : ''));
    }
  }
}
