
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { NavItem } from '~/models/Navigation';
import { Profile } from '~/models/Profile';

@Component
export default class NavigationDesktop extends Vue {
  @Prop({ type: Array, required: true }) navItems!: Array<NavItem>;
  @Prop({ type: Object, required: true }) userData!: Partial<Profile>;
  @Prop({ type: Function, required: true }) logoutCallback!: () => void;

  private get settingsItem(): NavItem | undefined {
    return this.navItems.find((item) => item.icon === 'settings');
  }
}
